import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

interface Props {
  data: {
    image: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { image } = props.data;

  return (
    <Layout departmentSlug="annals" title="Annals">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Annals</Core.DepartmentLabel>
        <Core.Flag>Milestone Minutes</Core.Flag>
        <Core.ContentSection>
          <br />
          <br />
          <p>
            As Johns Hopkins SAIS prepares to celebrate its 75th anniversary, it
            seems fitting to reflect on a significant moment in the school’s
            history. The school was founded in 1943 by Paul H. Nitze and
            Christian A. Herter and opened its doors in fall 1944. Their vision:
            a stand-alone graduate school that would prepare men and women to
            cope with the international responsibilities that would be thrust
            upon the United States during the postwar period. Recognizing the
            importance of this course of education, Johns Hopkins University
            leaders moved to have SAIS officially become a division of the
            university in 1950, as shown in these official minutes from the Oct.
            2, 1950, meeting of the executive committee of the Johns Hopkins
            University board of trustees.
          </p>
          <Core.FullWidthImage image={image} alt="" />
        </Core.ContentSection>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    image: file(
      relativePath: { regex: $directory }
      name: { eq: "sais-winter-19-annals" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default DepartmentContent;
